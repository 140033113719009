import { StatBox } from "./stat-box";
import Twitter from "../asset/twitter-outline.svg";
import Discord from "../asset/discord.svg";
import Files from "../asset/file.svg";
import { GradientText } from "./gradient-text";

export const Socials = () => {
  return (
    <>
      <div className="flex flex-col gap-16 items-center justify-center pb-10">
        <GradientText text="SOCIALS" />
        <div className="flex gap-x-20 gap-y-8 flex-wrap justify-center">
          <StatBox link="https://x.com/charles_thechad">
            <img src={Twitter} className="size-[64px]" />
            <div className="text-[35px] font-bold">Twitter</div>
          </StatBox>
          <StatBox link="https://discord.gg/BCpFUz9Xhs">
            <img src={Discord} className="size-[64px]" />
            <div className="text-[35px] font-bold">Discord</div>
          </StatBox>

          <StatBox link="https://giphy.com/channel/charlesthechad">
            <img src={Files} className="size-[64px]" />
            <div className="text-[35px] font-bold">GIFs</div>
          </StatBox>
        </div>
      </div>
    </>
  );
};
