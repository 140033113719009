import { twMerge } from "tailwind-merge";
import Charles from "../asset/charles.png";
import { useMemo } from "react";
import { LayeredText } from "../component/layered-text";
import { StatBox } from "../component/stat-box";
import { useRootContext } from "../context/root.context";
import { InView } from "react-intersection-observer";

const stats = [
  {
    text: "Chad",
    amount: 1,
    link: "",
  },
  {
    text: "Holders",
    amount: 1300,
    link: "https://www.taptools.io/charts/token/d8eb52caf3289a2880288b23141ce3d2a7025dcf76f26fd5659add06.7b22d6b425593f22941a8d850a35187051be4acfafa9059ea8a301e6fe42880c",
  },
  {
    text: "Followers",
    amount: 1432,
    link: "https://x.com/charles_thechad",
  },
  {
    text: "Memes",
    amount: "50+",
    link: "https://giphy.com/channel/charlesthechad",
  },
  {
    text: "Market cap",
    amount: "$10,3M",
    link: "https://www.taptools.io/charts/token/d8eb52caf3289a2880288b23141ce3d2a7025dcf76f26fd5659add06.7b22d6b425593f22941a8d850a35187051be4acfafa9059ea8a301e6fe42880c",
  },
  {
    text: "Volume",
    amount: "$819K",
    link: "https://www.taptools.io/charts/token/d8eb52caf3289a2880288b23141ce3d2a7025dcf76f26fd5659add06.7b22d6b425593f22941a8d850a35187051be4acfafa9059ea8a301e6fe42880c",
  },
  {
    text: "Liquidity",
    amount: "$745K",
    link: "https://www.taptools.io/charts/token/d8eb52caf3289a2880288b23141ce3d2a7025dcf76f26fd5659add06.7b22d6b425593f22941a8d850a35187051be4acfafa9059ea8a301e6fe42880c",
  },
];

export const Brand = () => {
  const { theme } = useRootContext();
  return (
    <>
      <div className="flex flex-col gap-8">
        <InView>
          {({ inView, ref, entry }) => (
            <img
              src={Charles}
              className={twMerge([
                "mx-auto z-10 relative duration-300 opacity-0 translate-y-[-40px] opacity-0 delay-150",
                theme === "dark" && "grayscale",
                inView && "opacity-100 translate-y-0",
              ])}
              ref={ref}
            />
          )}
        </InView>

        <div className="relative w-full mx-auto flex justify-center xl:gap-12 flex-col xl:flex-row items-center">
          <LayeredText
            text="CHARles"
            yOffset={10}
            colors={["#800080", "#CDFE03", "#fff", "#18161F"]}
          />
          <LayeredText
            text="THE"
            yOffset={10}
            colors={["#fff", "#800080", "#CDFE03", "#18161F"]}
          />
          <LayeredText
            text="CHAD"
            yOffset={10}
            colors={["#800080", "#CDFE03", "#fff", "#18161F"]}
          />
        </div>
        <div className="flex flex-wrap gap-x-16 gap-y-8 mx-auto max-w-[1200px] justify-center mt-10">
          {stats.map((s, i) => (
            <StatBox key={i} className={"delay-300"} link={s.link}>
              <div className="text-5xl font-bold">{s.amount}</div>
              <div className="text-[35px] uppercase font-bold">{s.text}</div>
            </StatBox>
          ))}
        </div>
      </div>
    </>
  );
};
