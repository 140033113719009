import { StatBox } from "./stat-box";
import Twitter from "../asset/twitter-outline.svg";
import Discord from "../asset/discord.svg";
import Files from "../asset/file.svg";
import { GradientText } from "./gradient-text";

export const Disclaimer = () => {
  return (
    <>
      <StatBox small={false} className="py-32  border-0 gap-8 mt-8">
        <GradientText text="DISCLAIMER" />
        <div className="comic-sans text-2xl text-center max-w-[900px]">
          CHAD is a memecoin with no intrinsic value or expectation of financial
          return.There is no formal team or roadmap. This coin exists solely for
          entertainment purposes.
        </div>
      </StatBox>
    </>
  );
};
