import React, { useEffect } from "react";
import { AppHeader } from "./component/app-header";
import { Footer } from "./component/footer";
import "./index.css";
import { Home } from "./page/home";
import { PageContainer } from "./component/page-container";
import { twMerge } from "tailwind-merge";
import { useRootContext } from "./context/root.context";

export const App = () => {
  const { theme } = useRootContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={twMerge([
        "bg-[#3C384D] flex flex-col duration-300 gap-3",
        theme === "light" && "bg-[#9997AA]",
      ])}
    >
      <AppHeader />
      <PageContainer>
        <Home />
      </PageContainer>

      <Footer />
    </div>
  );
};
