export const GradientText = ({ text }: { text: string }) => {
  return (
    <div className="relative text-bounces">
      <div className="uppercase absolute top-0 left-0 luckiest-guy  text-5xl md:text-6xl xl:text-8xl z-50 opacity-30 xl:translate-x-[-25px] xl:translate-y-[10px] translate-x-[-15px] translate-y-[10px]">
        {text}
      </div>
      <div
        style={{
          background:
            "linear-gradient(90deg, #3F53DC 0%, #BE219C 17%, #CD2887 29.5%, #FFC400 46%, #13C05B 74.26%, #1C18DB 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          WebkitTextStroke: "2px #18161F", // Stroke with black color
        }}
        className="uppercase luckiest-guy text-5xl  md:text-6xl xl:text-8xl z-50 relative"
      >
        {text}
      </div>
    </div>
  );
};
