import { ReactNode } from "react";
import Twitter from "../asset/twitter-fill.svg";
import File from "../asset/footer-file.svg";
import Discord from "../asset/discord-fill.svg";

export const Footer = () => {
  return (
    <>
      <div className="flex w-full bg-[#18161F] flex-col justify-center items-center gap-10 py-20">
        <div className="text-white font-bold text-6xl comic-sans uppercase text-center">
          Charles the chad
        </div>
        <div className="text-[#E8E8E8] comic-sans">
          @2024 All rights reserved
        </div>

        <div className="flex gap-6">
          <CircleContainer link="https://giphy.com/channel/charlesthechad">
            <img src={File} className="size-6" />
          </CircleContainer>
          <CircleContainer link="https://x.com/charles_thechad">
            <img src={Twitter} />
          </CircleContainer>
          <CircleContainer link="https://discord.gg/BCpFUz9Xhs">
            <img src={Discord} className="size-6 fill-black" />
          </CircleContainer>
        </div>
      </div>
    </>
  );
};

const CircleContainer = ({
  children,
  link,
}: {
  children?: ReactNode;
  link: string;
}) => {
  return (
    <>
      <a
        href={link}
        target="_blank"
        className="rounded-full bg-[#CDFE03] size-12 flex justify-center items-center hover:rotate-[360deg] duration-700 hover:duration-300"
      >
        {children}
      </a>
    </>
  );
};
