import React, { createContext, useContext, useState, ReactNode } from "react";

interface RootContextType {
  theme: string;
  toggleTheme: () => void;
}

const RootContext = createContext<RootContextType | undefined>(undefined);

export const useRootContext = () => {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error("useRootContext must be used within a RootProvider");
  }
  return context;
};

interface RootProviderProps {
  children: ReactNode;
}

const RootProvider: React.FC<RootProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>("dark");

  // Example shared logic
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Context value
  const contextValue: RootContextType = {
    theme,
    toggleTheme,
  };

  return (
    <RootContext.Provider value={contextValue}>{children}</RootContext.Provider>
  );
};

export default RootProvider;
