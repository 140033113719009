import { InView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";

export const LayeredText = ({
  text,
  colors,
  yOffset,
  className,
}: {
  text: string;
  colors: string[];
  yOffset: number;
  className?: string;
}) => {
  return (
    <>
      <InView className="h-[200px]">
        {({ inView, ref, entry }) => (
          <div className="relative flex h-fit" ref={ref}>
            <div
              className="text-6xl md:text-8xl opacity-0 luckiest-guy text-white h-fit"
              style={{ marginTop: `${yOffset * (colors.length - 1)}px` }}
            >
              {text}
            </div>
            {colors.map((c, i) => (
              <div
                style={{
                  color: c,
                  transform: `translateY(${
                    inView ? i * yOffset : -40 + i * 20
                  }px)`,
                  zIndex: 10 * (colors.length - i),
                  transitionDelay: `${i * 100 + 500}ms`,
                }}
                key={`${c}-${i}`}
                className={twMerge([
                  "absolute text-6xl md:text-8xl luckiest-guy font-outline duration-300",
                  "opacity-100",
                  !inView && "opacity-0",
                  className,
                ])}
              >
                {text}
              </div>
            ))}
          </div>
        )}
      </InView>
    </>
  );
};
