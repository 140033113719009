import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Button = ({
  theme,
  link,
  children,
  className,
}: {
  theme: "primary" | "secondary";
  link: string;
  children: ReactNode;
  className?: string;
}) => {
  if (theme === "primary")
    return (
      <a
        href={link}
        target="_blank"
        className={twMerge([
          "bg-[#CDFE03] border-[#18161F]",

          " h-fit py-2 border-2 font-bold  px-4 uppercase comic-sans hover:scale-[1.15] duration-300 ",
          className,
        ])}
      >
        {children}
      </a>
    );

  if (theme === "secondary")
    return (
      <a
        href={link}
        target="_blank"
        className={twMerge([
          "border-[#18161F] bg-[#18161F]",

          " border-2 text-[#CDFE03] font-bold border  h-fit py-2 px-4 uppercase comic-sans hover:scale-[1.15] duration-300",
          className,
        ])}
      >
        {children}
      </a>
    );
  return <></>;
};
