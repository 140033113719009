import { GradientText } from "./gradient-text";
import { StatBox } from "./stat-box";
import TokenomicIcon from "../asset/tokenomics.png";

export const Tokenomics = () => {
  return (
    <>
      <div className="flex flex-col gap-12 xl:gap-32 justify-center items-center mt-8 ">
        <div className="flex gap-4 items-center">
          <GradientText text="TOKENoMICS" />
        </div>
        <div className="flex gap-32">
          <img src={TokenomicIcon} className="hidden xl:flex" />
          <StatBox
            small={false}
            className="p-8 border-0 h-auto comic-sans min-h-[300px] font-normal text-2xl"
          >
            <div className="mb-8">
              CHAD token, launched on SnekFun platform, designed to prioritize
              liquidity and community engagement.
            </div>
            <ul className="list-disc ml-[40px] gap-6 flex-col flex">
              <li>30% of the tokens support liquidity pools </li>

              <li>
                5% are reserved for integrations, centralized exchange (CEX)
                listings{" "}
              </li>

              <li>
                65% were distributed to community participants from the Snek Fun
                launch
              </li>
            </ul>
          </StatBox>
        </div>
      </div>
    </>
  );
};
