import { StatBox } from "./stat-box";
import Twitter from "../asset/twitter-outline.svg";
import Discord from "../asset/discord.svg";
import Files from "../asset/file.svg";
import { GradientText } from "./gradient-text";

export const Origin = () => {
  return (
    <>
      <StatBox small={false} className="py-20 border-0 gap-8">
        <GradientText text="ORIGIN" />
        <div className="comic-sans text-2xl text-center max-w-[900px] font-bold">
          Born from the relentless mockery of Charles Hoskinson, Charles the
          Chad emerged as the ultimate avenger. Built from haters’ insults, he
          dismantles critics with meme-fueled muscles and unyielding wit.
          <br />
          <br />
          His purpose? To obliterate misinformation and leave ignorance face
          down in the dirt.
          <br />
          <br />
          Trolls crumble as he flips narratives, turning scorn into dust.
          Charles the Chad isn’t just a defender of Cardano—he’s a symbol of
          resilience, proving innovation thrives even under fire.
        </div>
      </StatBox>
    </>
  );
};
