import { Socials } from "../component/socials";
import { Origin } from "../component/origin";
import { Tokenomics } from "../component/tokenomics";
import { Disclaimer } from "../component/disclaimer";
import { Brand } from "../component/brand";

export const Home = () => {
  return (
    <>
      <Brand />

      <Socials />
      <Origin />
      <Tokenomics />
      <Disclaimer />
    </>
  );
};
