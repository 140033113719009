import { twMerge } from "tailwind-merge";
import Logo from "../asset/logo.svg";
import MobileStack from "../asset/mobile-stack.svg";
import { useRootContext } from "../context/root.context";
import { InView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Button } from "./button";
import Charles from "../asset/charles.png";

export const AppHeader = () => {
  const { toggleTheme, theme } = useRootContext();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!expanded) document.body.style.overflow = "auto";
    if (expanded) document.body.style.overflow = "hidden";
  }, [expanded]);

  return (
    <>
      <InView>
        {({ inView, ref, entry }) => (
          <div
            ref={ref}
            className={twMerge([
              "w-full max-w-[1000px] mx-auto bg-[#DDDAEC] flex items-center justify-between gap-3 md:rounded-full md:px-6 md:pl-12 h-[80px] mb-10 top-0 md:mt-6 sticky md:top-6 z-[100] duration-300",
              "md:shadow-[-6px_10px_0px_0px_rgba(0,0,0,0.75)]",
              "px-3",
              theme === "light" && "bg-white",
              !inView && "opacity-0 translate-y-[-20px]",
              inView && "opacity-100 translate-y-0",
            ])}
          >
            <div className="flex items-center">
              <img src={Logo} className="size-[60px]" />
              <div className="text-[#201E2A] text-4xl sriracha">CHAD</div>
            </div>

            <div className="gap-4 items-center flex">
              <img
                src={MobileStack}
                className="size-10 cursor-pointer md:hidden"
                onClick={() => setExpanded(!expanded)}
              />
              <Button
                theme="primary"
                className="hidden md:flex"
                link="https://giphy.com/channel/charlesthechad"
              >
                Memes
              </Button>
              <Button
                theme="secondary"
                link="https://app.dexhunter.io/swap?tokenIdSell=&tokenIdBuy=97075bf380e65f3c63fb733267adbb7d42eec574428a754d2abca55b436861726c6573207468652043686164"
                className="hidden md:flex"
              >
                Get chad
              </Button>
              <div
                className={twMerge([
                  "relative size-[35px] rounded-full overflow-hidden cursor-pointer duration-300 ",
                  theme === "dark" && "rotate-180",
                  "hover:scale-[1.15]",
                ])}
                onClick={() => toggleTheme()}
              >
                <div className="absolute inset-0 bg-black" />
                <div
                  className={twMerge([
                    "absolute top-1 right-1 h-[28px] w-[14px] bg-white",
                    theme === "dark" && " bg-[#DDDAEC]",
                  ])}
                  style={{ borderRadius: "0 100rem 100rem 0" }}
                />
              </div>
            </div>
          </div>
        )}
      </InView>
      <div
        className={twMerge([
          "fixed top-[80px] left-0 right-0 bottom-0 z-[1000] flex flex-col items-center p-4 pb-8 pt-16 gap-4 justify-between translate-x-0 duration-150",
          !expanded && "translate-x-full",
          theme === "dark" ? "bg-[#3C384D] " : "bg-[#DDDAEC] ",
        ])}
      >
        {/* <InView>
          {({ inView, ref, entry }) => (
            <img
              src={Charles}
              className={twMerge([
                "mx-auto z-10 relative duration-300 opacity-0 translate-y-[-40px] opacity-100 delay-150",
                theme === "dark" && "grayscale",
              ])}
              ref={ref}
            />
          )}
        </InView> */}
        <div></div>
        <div className="flex flex-col gap-4 w-full">
          <Button
            theme="primary"
            link="https://giphy.com/channel/charlesthechad"
            className="w-full text-center"
          >
            Memes
          </Button>
          <Button
            theme="secondary"
            link="https://app.dexhunter.io/swap?tokenIdSell=&tokenIdBuy=97075bf380e65f3c63fb733267adbb7d42eec574428a754d2abca55b436861726c6573207468652043686164"
            className="w-full text-center"
          >
            Get chad
          </Button>
        </div>
      </div>
    </>
  );
};
