import { ReactNode } from "react";
import Shadow from "../asset/box-shadow.png";
import { twMerge } from "tailwind-merge";
import { useRootContext } from "../context/root.context";
import { InView, useInView } from "react-intersection-observer";
export const StatBox = ({
  children,
  className,
  small = true,
  link,
}: {
  children: ReactNode;
  small?: boolean;
  className?: string;
  link?: string;
}) => {
  const { theme } = useRootContext();

  return (
    <>
      <InView>
        {({ inView, ref, entry }) => (
          <a
            ref={ref}
            className={twMerge([
              "relative duration-300",
              "bg-white h-full justify-center sriracha text-[#201E2A] border-4 border-[#201E2A] flex flex-col py-2  relative items-center duration-300",
              "px-3 md:px-8",
              small && "min-w-[240px] min-h-[180px] flex-1 max-w-[280px]",
              theme === "dark" && "bg-[#DDDAEC]",
              !inView && "opacity-0 translate-y-[10%]",
              inView && "opacity-100 translate-y-0",
              className,
              small && "hover:translate-y-[-20px] hover:delay-0 hover:bg-white",
            ])}
            style={{
              boxShadow: small
                ? "-10px 10px 0px 0px rgba(32,30,42,100)"
                : "-30px 30px 0px 0px rgba(32,30,42,100)",
            }}
            href={link}
            target="_blank"
          >
            <div
              className={twMerge([
                "absolute top-0",
                small ? "left-[-14px] top-[-4px]" : "left-[-30px]",
              ])}
            >
              <Triangle position="left" small={small} />
            </div>
            <div
              className={twMerge([
                "absolute right-0",
                small ? "bottom-[-14px] right-[-4px]" : "bottom-[-30px]",
              ])}
            >
              <Triangle position="right" small={small} />
            </div>
            {children}
          </a>
        )}
      </InView>
    </>
  );
};

const Triangle = ({
  position,
  small = true,
}: {
  position: "left" | "right";
  small?: boolean;
}) => {
  return (
    <div
      className={twMerge([
        "w-0 h-0  border-[#201e2a] border-r-0",
        small
          ? "border-l-[11px] border-b-[11px]"
          : "border-l-[30px] border-b-[30px]",
        position === "left" && "border-l-transparent border-r-0",
        position === "right" && "border-b-transparent border-r-0",
      ])}
    ></div>
  );
};
